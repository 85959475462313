footer{
  margin-top: 35px;
  background:url('/images/bois.jpg') no-repeat;
  background-size: cover;
  height: 72px;
  display: block;
  box-sizing: border-box;
  padding: 10px 26px;
  color: #FFF;
  font-weight: 700;
  .container{
    position: relative;
    .fb a{
      position: absolute;
      top: 0px;
      right: 0;
      display: block;
      width: 42px;
      height:42px;
      background: url('/images/FB.png') no-repeat;
    }
  }
  p{
    text-transform: uppercase;
    font-weight: 300;
    margin:0;
  }
}