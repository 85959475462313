header{
  background-color: transparent;
  nav ul li{
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 500;
    &.col-xs-1{
      display: table;
      width: 8%;
      a {
        vertical-align: middle;
        text-align: center;
        display: table-cell;
      }
    }
    &.active{
      a{
        &:link,
        &:visited,
        &:active,
        &:focus,
        &:hover{
          color: $main-red;
        }
      }
    }
    a{
      &:link,
      &:visited,
      &:active,
      &:focus,
      &:hover{
        color: #000;
      }
    }
    &.logo{
      display: inline-block;
      a{
        display: block;
        width: 120px;
        height: 93px;
        background: url('/images/Pension-FondBlanc-Noir.png') no-repeat;
        text-indent: -9999em;
      }
    }
    list-style: none;
    display: inline-block;
  }
}

header nav {
  display: block;
  width: 60%;
  margin: 0 auto;
  .mobile ul {
    padding-left: 0;
    text-align: center;
  }
  ul.desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: flex-start;
    width: 100%;
    li {
      flex-grow: 1;
      text-align: center;
      align-self: center;
      &.logo{
        display: inline-block;
        a{
          position: relative;
          display: block;
          width: 120px;
          background: url('/images/Pension-FondBlanc-Noir.png') no-repeat;
          text-indent: -9999em;
        }
      }
    }
  }
}

@media (max-width:1280px){
  header nav{
    width: 100%;
  }
}
@media (max-width:768px){
  header nav{
    ul{
      background-color: rgba(255,255,255,1);
    }
    .display-block li{
      display: block;
      width:100%;
      height: auto;
    }
    ul.desktop{
      display: none;
    }
  }
}
