$main-red: #a71a17;

// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "header";
@import "footer";

html, body{
  background-color: #fff;
  position: relative;
}
.main-red{
  color: $main-red;
  font-weight: 700;
}
.text-white{
  color: #FFF;
}
.text-black{
  color: #000;
}

.content{
  margin: 70px 0;
}

.list-style-none{
  padding-left: 0;
  li{
    list-style: none;
  }
}
.z-index-2{
  position: relative;
  z-index: 2;
}
.z-index-9999{
  position: relative;
  z-index: 9999;
}
.width-100{
  width:100%;
  display: block;
}
.mobile{
  display: none;
}
.desktop{
  display: block;
}
@media (max-width: 768px){
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  html, body{
    color: #000;
  }
  .container p{
    margin: 0 0 25px;
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content{
    margin-bottom: 35px;
  }
}
.faq-accueil.mobile{
  .panel-title{
    background: url(/images/PICTO-01.png) 100% 50% no-repeat;
    background-size: auto 15px;
    a {
      width:100%;
      height:100%;
      display: block;
    }
  }
}
img.mobile{
  margin: 55px 0 15px;
}

@media (min-width: 769px) {
  div.toilettage {
    background: url(/images/CA_toilettage.jpg) 50% no-repeat;
    background-size: cover;
    min-height: 410px;
    margin-top:75px;
    p{
      color: #000;
    }
    .content{
      max-width: 50%;
    }
  }
}